import React from 'react';

const BoysShortsItems = () => {
 return (
  <div>
   <div className="ttm-service-single-content-area">
    <div className="row mb_15 mt_15">
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/Boy's/shorts/Boys-Short-Cargo-1.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a className="ttm_prettyphoto ttm_image" title="Boy's Short-1" data-rel="prettyPhoto" href="images/Boy's/shorts/Boys-Short-Cargo-1.jpg">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Boy's Short-1</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/Boy's/shorts/Boys-Short-Cargo-2.jpg" alt=" image" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>{/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Boy's Short-2</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/Boy's/shorts/Boys-Short-Cargo-3.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Boy's Short-3</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/Boy's/shorts/Boys-Short-Cargo-4.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Boy's Short-4</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/Boy's/shorts/Boys-Short-Cargo-6.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Boy's Short-5</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/Boy's/shorts/Boys-Short-Cargo-7.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Boy's Short-6</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/Boy's/shorts/Boys-Short-Cargo-8.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Boy's Short-7</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
    </div>
   </div>

  </div>
 );
};

export default BoysShortsItems;
