import React from 'react';

const MensShirtsProducts = () => {
 return (
  <div>
   <div className="ttm-service-single-content-area">
    <div className="row mb_15 mt_15">
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Checked-Shirt-with-Patch-Pocket-2.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Checked Shirt with Patch Pocket-1</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Checked-Shirt-with-Patch-Pocket.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>{/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Checked Shirt with Patch Pocket-2</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Checked-Slim-Fit-Shirt-1.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Checked Slim Fit Shirt-1</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Checked-Slim-Fit-Shirt-with-Patch-Pocket-1.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Checked Slim Fit Shirt with Patch Pocket-1</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Checked-Slim-Fit-Shirt-with-Patch-Pocket-2.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Checked Slim Fit Shirt with Patch Pocket-2</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Checked-Slim-Fit-Shirt-with-Patch-Pocket-3.png " alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Checked Slim Fit Shirt with Patch Pocket-3</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Checked-Slim-Fit-Shirt-with-Patch-Pocket-4.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Checked Slim Fit Shirt with Patch Pocket-4</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Checked-Slim-Fit-Shirt-with-Patch-Pocket-5.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Shirt with Patch Pocket-5</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Checked-Slim-Fit-Shirt-with-Patch-Pocket.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a className="ttm_prettyphoto ttm_image" title="Shirt with Patch Pocket-6" data-rel="prettyPhoto" href="images/menjeans/shirts/Checked-Slim-Fit-Shirt-with-Patch-Pocket.png">
          <i className="fa fa-search" />
         </a>
         {/* <a href="wool-fabric.html" class="ttm_link"><i class="fa fa-random"></i></a> */}
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Shirt with Patch Pocket-6</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Checked-Slim-Fit-Shirt.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Checked Slim Fit Shirt</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Cotton-Shirt-with-Flap-Pockets.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Cotton Shirt with Flap Pocket</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Cotton-Shirt-with-Patch-Pocket.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a className="ttm_prettyphoto ttm_image" title="Cotton Shirt with Patch Pocket" data-rel="prettyPhoto" href="images/menjeans/shirts/Cotton-Shirt-with-Patch-Pocket.png">
          <i className="fa fa-search" />
         </a>
         {/* <a href="wool-fabric.html" class="ttm_link"><i class="fa fa-random"></i></a> */}
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Cotton Shirt with Patch Pocket</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Full-Sleeves-Slim-Fit-Shirt-1.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Full Sleeves Slim Fit Shirt-1</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Hooded-Checked-Shirt-with-Patch-Pocket.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Hooded Checked Shirt with Patch Pocket</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Oxford-Shirt-With-Button-Down-Collar-1.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Oxford Shirt With Button Down Collar-1</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Oxford-Shirt-With-Button-Down-Collar.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Oxford Shirt With Button Down Collar-2</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Printed-Slim-Fit-Shirt-with-Patch-Pocket.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Printed Slim Fit Shirt with Patch Pocket</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Shirt-with-Band-Collar.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Shirt with Band Collar</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Shirt-with-Patch-Pocket.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Shirt with Patch Pocket</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Slim-Fit-Shirt-with-Flap-Pockets.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Slim Fit Shirt with Flap Pocket</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Slim-Fit-Shirt-with-Patch-Pocket-1.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Slim Fit Shirt with Patch Pocket-1</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Slim-Fit-Shirt-with-Patch-Pocket.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Slim Fit Shirt with Patch Pocket</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Slim-Fit-Shirt-with-Spread-Collar.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Slim Fit Shirt with Spread Collar</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Slim-Fit-Shirt.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Slim Fit Shirt</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Striped-Cotton-Shirt-with-Patch-Pocket.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Striped Cotton Shirt with Patch Pocket</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Striped-Slim-Fit-Cotton-Shirt.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Striped Slim Fit Cotton Shirt</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Striped-Slim-Fit-Shirt-with-Patch-Pocket-1.png" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Striped Slim Fit Shirt with Patch Pocket-1</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Striped-Slim-Fit-Shirt-with-Patch-Pocket.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Striped Slim Fit Shirt with Patch Pocket</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/menjeans/shirts/Striped-Slim-Fit-Shirt.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Striped Slim Fit Shirt</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
    </div>
   </div>


  </div>
 );
};

export default MensShirtsProducts;


