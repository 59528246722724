import React from 'react';

const SustainableBanner = () => {
    return (
        <div>
            <div className="banner_slider">
                <div className="slide " style={{ height: 400 }}>
                    <div className="slide_img " style={{ backgroundImage: 'url(images/slides/eco_friendly_fashion_terms.webp)' }}>
                    </div>
                    <div className="slide__content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slide__content--headings ttm-textcolor-white text-center">
                                        <h2 data-animation="fadeInDown">
                                            "We accompany our customer for sustainable
                                            product development and specialized sourcing strategies, combined with
                                            compliance.
                                            "
                                        </h2>
                                        {/* <div class="breadcrumb-wrapper">
                      <span>
                          <a title="Homepage" href="index.html">Home</a>
                      </span>
                      <span>"Leather Bags & Belts"</span>
                  </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SustainableBanner;