import React from 'react';
import OurCertificate from '../Components/SustainableApparels/OurCertificate';
import OurGuideline from '../Components/SustainableApparels/OurGuideline';
import StageFour from '../Components/SustainableApparels/StageFour';
import StageOne from '../Components/SustainableApparels/StageOne';
import StageThree from '../Components/SustainableApparels/StageThree';
import StageTwo from '../Components/SustainableApparels/StageTwo';
import SustainableBanner from '../Components/SustainableApparels/SustainableBanner';

const SustainableApparel = () => {
    return (
        <div>
            <SustainableBanner></SustainableBanner>
            <OurCertificate></OurCertificate>
            <OurGuideline></OurGuideline>
            <StageOne></StageOne>
            <StageTwo></StageTwo>
            <StageThree></StageThree>
            <StageFour></StageFour>
        </div>
    );
};

export default SustainableApparel;