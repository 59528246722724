import React from 'react';
import ReadMore from './ReadMore';

const WhyClientChooseUsSection = () => {
    return (

        < section className="ttm-row grid-section ttm-bgcolor-grey clearfix" >
            <div className="container">
                {/* row */}
                <div className="row">
                    <div className="col-lg-12">
                        {/* section title */}
                        <div className="section-title title-style-center_text">
                            <div className="title-header">
                                <h2 className="title"><b>Why Client </b> Choose Us</h2>
                            </div>
                        </div>{/* section title end */}
                    </div>
                </div>
                <div className="row mb_15">
                    <div className="col-lg-4">
                        {/* featured-imagebox-post */}
                        <div className="featured-imagebox featured-imagebox-post style1">
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3 style={{ color: '#c3002f' }}>Product Development
                                    </h3>
                                </div>
                                <ReadMore
                                    text="During the product development process, we strictly monitor a checklist
                                    of product attributes to keep exploring opportunities for 
                                    development and innovation, and challenge ourselves to outrun previous standards.
                                        It is during this process that we analyse your product requirement and
                                        assess its environmental and social impacts. In addition, we always ask
                                        our suppliers to do the same."
                                    maxChars={160}
                                />

                            </div>
                        </div>{/* featured-imagebox-post end */}
                    </div>
                    <div className="col-lg-4">
                        {/* featured-imagebox-post */}
                        <div className="featured-imagebox featured-imagebox-post style1">
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3 style={{ color: '#c3002f' }}>Production
                                    </h3>
                                </div>

                                <ReadMore
                                    text="We offer seamless production services in Asia. Our unique profile lies
                                    in
                                    the combination of a stylish European perspective and 
                                     a strong Asian
                                        presence. As a single point of contact we cover the complete production
                                        chain to ensure impeccable quality and a smooth and pleasant production
                                        experience.Looking for design and development support? Want to switch
                                        current operations to a more cost effective country like Bangladesh? Or
                                        scouting for a partner who can complement your own production on special
                                        projects? Some of our clients want us to be involved from the very first
                                        sketch, where we provide creative input and relevant market research,
                                        while
                                        others choose only a specific service from our holistic offering. We
                                        make
                                        sourcing simple."
                                    maxChars={160}
                                />

                            </div>
                        </div>{/* featured-imagebox-post end */}
                    </div>
                    <div className="col-lg-4">
                        {/* featured-imagebox-post */}
                        <div className="featured-imagebox featured-imagebox-post style1">
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3 style={{ color: '#c3002f' }}>Design
                                    </h3>
                                </div>
                                <div className="featured-desc">
                                    <ReadMore
                                        text="Each season we scout the market for the latest styles and qualities.Our
                                        research and development team have long experience 
                                       from fashion trend
                                            forecasting and customer analysis. To make sure you get the most modern
                                            products they continuously educate our partner factories in new
                                            innovation
                                            and production techniques.
                                            We offer various kinds of creative services, from complimentary
                                            consultation
                                            to complete creation processes. Tell us about your idea, visit our
                                            showrooms
                                            for inspiration or request a run through of the latest yarns, washes or
                                            knittings adding a new dimension to your design."
                                        maxChars={161}
                                    />

                                </div>
                            </div>
                        </div>{/* featured-imagebox-post end */}
                    </div>
                    <div className="col-lg-4">
                        {/* featured-imagebox-post */}
                        <div className="featured-imagebox featured-imagebox-post style1">
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3 style={{ color: '#c3002f' }}>Products
                                    </h3>
                                </div>
                                <div className="featured-desc">
                                    <ReadMore
                                        text="The sourcing industry is constantly changing. It is complex and require
                                        frequent and passionate work to keep up to date with
                                         what countries is
                                            most beneficial for certain products. It also demands devotion when it
                                            comes
                                            to codes of conducts, making sure that regulations and standards are
                                            followed.With our local presence, expertise and good relations We
                                            guarantee a
                                            qualitative production throughout the whole chain, competitive pricing
                                            and a great care for labour and environmental questions. Thanks to long
                                            and
                                            close collaborations with our partner factories We have the capacity to
                                            perform both on a larger scale as well as specific projects. Discover
                                            our wide
                                            range of products and don’t hesitate to get in touch on any ideas you
                                            might
                                            have,we love a good chat, cost effective solutions and creative
                                            challenges."
                                        maxChars={161}
                                    />

                                </div>
                            </div>
                        </div>{/* featured-imagebox-post end */}
                    </div>
                    <div className="col-lg-4">
                        {/* featured-imagebox-post */}
                        <div className="featured-imagebox featured-imagebox-post style1">
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3 style={{ color: '#c3002f' }}>Quality Checks
                                    </h3>
                                </div>
                                <div className="featured-desc">
                                    <ReadMore
                                        text=" that
                                        the most cost-efficient way to a perfect production 
                                       is to get it right
                                            from
                                            the very start. To do so we have several steps in our quality assurance
                                            process, all included in our services. At Eminent Textiles quality of
                                            course
                                            cover impeccable fabrics, colours, fits and cuts but it also means more
                                            than
                                            that. To us is as much about the product as the people producing it and
                                            we
                                            highly emphasis our CSR and highly developed and forward thinking
                                            policies
                                            on decreased use of chemicals and less impact on environment. We carry
                                            out
                                            several quality checks throughout the process and we rather send you and
                                            extra sample than getting a delivery of disappointment. We work with
                                            proven
                                            processes to ensure a quality as expected:&nbsp;
                                            Local QC
                                            Frequent quality check at customers requested AQL (Acceptable Quality
                                            Limit)
                                            is key to a cost effective production. We offer constant monitoring of
                                            orders including production updates, We adjust and always offer
                                            alternatives
                                            if needed, our goal is to get you the perfect product."
                                        maxChars={160}
                                    />

                                </div>
                            </div>
                        </div>{/* featured-imagebox-post end */}
                    </div>
                    <div className="col-lg-4">
                        {/* featured-imagebox-post */}
                        <div className="featured-imagebox featured-imagebox-post style1">
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3 style={{ color: '#c3002f' }}>Logistics
                                    </h3>
                                </div>
                                <div className="featured-desc">
                                    <ReadMore
                                        text=" Everyone in sourcing knows that logistics can be a limbo. Everyone can
                                    organise a delivery but getting them in time, to the best
                                   price and at
                                        the
                                        right terms requires special skills. Let Eminent Textiles competent team
                                        do
                                        the puzzle. As your partner we can take care of administration, delivery
                                        condition agreements and custom clearance and as an international player
                                        we
                                        have vast experience from various order volumes, country specific terms
                                        and
                                        other aspects affecting cost and efficiency. We carefully see to each
                                        order,
                                        case by case, supporting you wherever you need. Let us take care of the
                                        complete process or give us a call for guidance. Professionalism,
                                        personal
                                        service and perfection is always our promise."
                                        maxChars={162}
                                    />

                                </div>
                            </div>
                        </div>{/* featured-imagebox-post end */}
                    </div>
                </div>
            </div>
        </section >
    );
};

export default WhyClientChooseUsSection;



