import React from 'react';
import Client from '../Components/Client';
import Contact from '../Components/ContactUs/Contact';
import ContactBanner from '../Components/ContactUs/ContactBanner';
import ContactForm from '../Components/ContactUs/ContactForm';



const ContactUs = () => {
    return (
        <div>
            <ContactBanner></ContactBanner>
            <ContactForm></ContactForm>
            <Contact></Contact>
            <Client></Client>
        </div>
    );
};

export default ContactUs;



