import React from 'react';

const StageThree = () => {
    return (
        <div>
            <section className="ttm-row about-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="ttm_single_image-wrapper text-left">
                                <img style={{ width: 600, height: 400 }} className="img-fluid" src="images/slides/set.jpg" alt="single-09" />
                            </div>
                        </div>
                        <div style={{ marginTop: 40 }} className="col-lg-6 col-md-12 col-xs-12">
                            <div className="padding_top20 res-991-padding_top40">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3 style={{ fontSize: 30 }}>Stage 3</h3>
                                    </div>
                                    <div className="title-desc">
                                        <p><b>Better Products</b><br />
                                            Products should line up with environmental, traceability assurance or social
                                            standard.
                                        </p><li>Products carry out sustainable development goals (from materials to
                                            manufacturing conditions, enabling best green practices and economic
                                            development)</li>
                                        <li>Product is tested and certified for hazard chemicals</li>
                                        <li>Finished product completely made by local sourcing and development</li>
                                        <p />
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>

        </div>
    );
};

export default StageThree;