import React from 'react';

const WhoWeAreSection = () => {
    return (
        <div>
            <section className="ttm-row about-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="ttm_single_image-wrapper text-left">
                                <img style={{ width: 600, height: 400 }} className="img-fluid" src="images/slides/fashion.jpg" alt="single-09" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xs-12">
                            <div className="padding_top20 res-991-padding_top40">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3 style={{ fontSize: 30 }}>WHO WE ARE</h3>
                                    </div>
                                    <div className="title-desc">
                                        <p>EMINENT TEXTILES is your bridge to easy-flowing fashion production in
                                            Bangladesh. We care our customers’ value and always bring newness and
                                            transformation to our work, offering an immense multi-category product
                                            range, manufactured in over 100 fully compliant partner factories in
                                            Bangladesh.
                                            We deal with complicated communications, world-class quality assurance and
                                            beneficial pricing negotiation.
                                        </p>
                                        <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-skincolor" href="aboutus.html">read more</a>
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>
        </div>
    );
};

export default WhoWeAreSection;