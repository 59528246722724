import React from 'react';
import Banner from '../Components/OurPartners/Banner';
import OurClient from '../Components/OurPartners/OurClient';
import OurPartnersContrainer from '../Components/OurPartners/OurPartnersContainer';


const OurPartners = () => {
    return (
        <div>
            <Banner></Banner>
            <OurPartnersContrainer></OurPartnersContrainer>
            <OurClient></OurClient>
        </div>
    );
};

export default OurPartners;