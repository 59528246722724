import React from 'react';
import Client from '../Components/Client';
import LifeWithUsBanner from '../Components/LifeWithUs/LifeWithUsBanner';
import LifeWithUsMain from '../Components/LifeWithUs/LifeWithUsMain';
import LifeWithUsOurPartners from '../Components/LifeWithUs/LifeWithUsOurPartners';
import LifeWithUsPartners from '../Components/LifeWithUs/LifeWithUsPartners';

const LifeWithUs = () => {
    return (
        <div>
            <LifeWithUsBanner></LifeWithUsBanner>
            <LifeWithUsMain></LifeWithUsMain>
            <LifeWithUsOurPartners></LifeWithUsOurPartners>
            <LifeWithUsPartners></LifeWithUsPartners>
            <Client></Client>
        </div>
    );
};

export default LifeWithUs;