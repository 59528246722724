import React from 'react';

const StageFour = () => {
    return (
        <div>
            <section className="ttm-row about-section ttm-bgcolor-grey clearfix">
                <div className="container">
                    <div className="row">
                        <div style={{ marginTop: 50, textAlign: 'left' }} className="col-lg-6 col-md-12">
                            <div className="padding_top20 res-991-padding_top40">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3 style={{ fontSize: 30 }}>Stage 4</h3>
                                    </div>
                                    <div className="title-desc">
                                        <p><b>Superior Products
                                        </b><br />
                                            Products should directly contribute to wellbeing, and completely cycled by
                                            nature for ecological business development.
                                        </p><li>80% of products are made with natural materials &amp; organic processes</li>
                                        <li>Friendly impact on environment and end-user</li>
                                        <li>Best practices for purchasing</li>
                                        <p />
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xs-12">
                            <div className="ttm_single_image-wrapper text-left">
                                <img style={{ width: 600, height: 400 }} className="img-fluid" src="images/slides/sddefault.jpg" alt="single-09" />
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>

        </div>
    );
};

export default StageFour;