import React from 'react';

const ContactForm = () => {
    return (
        <div>
            <section className="ttm-row padding_bottom_zero-section clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ttm-bgcolor-white box-shadow p-50">
                                {/* section title */}
                                <div className="section-title title-style-center_text">
                                    <div className="title-header">
                                        <h2 className="title">Contact <b>Form</b></h2>
                                    </div>
                                    <div className="title-desc">
                                        <p>Feel free to contact us through <a className="ttm-textcolor-skincolor" href="#">Skype ,</a> <a className="ttm-textcolor-skincolor" href="#">Linkedin</a> or
                                            <a className="ttm-textcolor-skincolor" href="#">Whatsapp</a>
                                            if you prefer.
                                        </p>
                                    </div>
                                </div>{/* section title end */}
                                <form id="request_qoute_form" className="request_qoute_form wrap-form clearfix" method="post" noValidate="novalidate" action="https://formspree.io/f/moqowdgz">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <span className="text-input"><input name="name" type="text" placeholder="Your Name" required="required" /></span>
                                        </div>
                                        <div className="col-md-4">
                                            <span className="text-input"><input name="address" type="text" placeholder="Your Email" required="required" /></span>
                                        </div>
                                        <div className="col-md-4">
                                            <span className="text-input"><input name="phone" type="text" placeholder="Phone Number" required="required" /></span>
                                        </div>
                                        <div className="col-md-6">
                                            <span className="text-input"><input name="company name*" type="text" placeholder="Company Name*" required="required" /></span>
                                        </div>
                                        <div className="col-md-6">
                                            <span className="text-input"><input name="subject" type="text" placeholder="Subject" required="required" /></span>
                                        </div>
                                        <div className="col-lg-12">
                                            <span className="text-input"><textarea name="message" rows={5} placeholder="Message" required="required" defaultValue={""} /></span>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="pt-15 text-center">
                                                <p className="cookies padding_bottom20">
                                                    <input id="cookies-consent" name="cookies-consent" type="checkbox" defaultValue="yes" />
                                                    <label htmlFor="cookies-consent">Save my name, email, and website in
                                                        this browser for the next time I comment.</label>
                                                </p>
                                                <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor" type="submit">Send now!</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>

        </div>
    );
};

export default ContactForm;
