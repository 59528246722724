import React from 'react';
import AboutUsBanner from '../Components/AboutUs/AboutUsBanner';
import OurMission from '../Components/AboutUs/OurMission';
import OurValues from '../Components/AboutUs/OurValues';
import OurVision from '../Components/AboutUs/OurVision';



const Aboutus = () => {

    return (
        <div>
            <AboutUsBanner></AboutUsBanner>
            <OurMission></OurMission>
            <OurVision></OurVision>
            <OurValues></OurValues>
        </div>
    );
};


export default Aboutus;