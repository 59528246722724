import React from 'react';
import WomenApparelBanner from '../WomenApparelBanner';
import WomenAside from '../WomenAside';
import WomentankTopItems from './WomentankTopItems';

const WomensTankTop = () => {
    return (

        <div>
            <WomenApparelBanner />
            <div className="title-header section-title title-style-center_text">
                <h2 className="title font">Women's <b> Tank Tops </b></h2>
            </div>
            <div className="ttm-row sidebar ttm-sidebar-left clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-4 widget-area sidebar-left side-category">
                            <WomenAside />

                        </div>
                        <div className="col-lg-8 content-area example">
                            <WomentankTopItems />
                        </div>
                    </div>
                    {/* row end */}
                </div>
            </div>
        </div>

    );
};

export default WomensTankTop;