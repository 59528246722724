import React from 'react';
import MensApparelsBanner from '../MensApparelsBanner';
import MensMain from './MensMain';

const MensJeans = () => {
    return (
        <div>
            <MensApparelsBanner></MensApparelsBanner>
            <MensMain></MensMain>
        </div>
    );
};

export default MensJeans;