import React from 'react';
import { NavLink } from 'react-router-dom';

const WomenAside = () => {
    const navLinkStyles = ({ isActive }) => {
        return {
            fontWeight: isActive ? "bold" : "normal",

            color: isActive ? "brown" : "black"

        }

    }
    return (
        <div>
            <div>
                <aside className="widget widget-nav-menu">
                    <ul>
                        <li><NavLink to="/womensjeans" style={navLinkStyles}>Women's Jeans </NavLink></li>
                        <li><NavLink to="/womenleggings" style={navLinkStyles}>Women's Leggings </NavLink></li>
                        <li><NavLink to="/womentanktop" style={navLinkStyles}> Women's Tank Tops </NavLink></li>
                        <li><NavLink to="/womenshirts" style={navLinkStyles}> Women's Shirts </NavLink></li>
                        <li><NavLink to="/womentshirts" style={navLinkStyles}> Women's T Shirts </NavLink></li>
                    </ul>
                </aside>
                <aside className="widget widget-download with-title">
                    <div className="d-flex download_block">
                        <div className="download_img_icon">
                            <img className="img-fluid auto_size" width={46} height={53} src="images/download-pdf.png" alt="download-pdf-img" />
                        </div>
                        <div className="padding_left20">
                            <p className="ttm-textcolor-skincolor margin_bottom0">Company</p>
                            <h2 className="fs-18">Profile</h2>
                            <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-dark ttm-icon-btn-left" href="#"><i className="ti ti-arrow-right" /> Download (3.5 MB)</a>
                        </div>
                    </div>
                </aside>
                <aside className="widget widget-banner">
                    <div className="ttm-col-bgcolor-yes ttm-bgcolor-darkgrey col-bg-img-seven ttm-col-bgimage-yes ttm-bg">
                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                            <div className="ttm-col-wrapper-bg-layer-inner" />
                        </div>
                        <div className="layer-content">
                            <div className="widget-banner-inner">
                                <h3 className="ttm-textcolor-skincolor">Have Questions?</h3>
                                <p>Our Client Care Managers Are On Call 24/7 To Answer Your Question.</p>
                                <ul>
                                    <li><i className="flaticon flaticon-phone-call" />+8801766555756</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </aside>
                <aside className="widget contact-widget with-title">
                    <h3 className="widget-title">Get in touch</h3>
                    <ul className="contact-widget-wrapper">
                        <li><i className="ttm-textcolor-skincolor ti ti-location-pin" />Flat 2/A,House 04,Road
                            13, Nikunja 2,Dhaka -1229, Bangladesh</li>
                        <li><i className="ttm-textcolor-skincolor ti ti-email" /><a href="mailto:shakil@eminenttextiles.com" target="_blank">shakil@eminenttextiles.com</a></li>
                        <li><i className="ttm-textcolor-skincolor ti ti-mobile" />+8801766555756</li>
                        <li><i className="ttm-textcolor-skincolor ti ti-alarm-clock" />Mon to Sat - 9:00am to
                            6:00pm <br /> (Sunday Closed)</li>
                    </ul>
                </aside>
            </div>

        </div>
    );
};

export default WomenAside;