import React from 'react';
import Slider from 'react-slick';

const BoysApparelsBanner = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,

    }
    return (
        <div>
            <Slider className="banner_slider " {...settings}>
                <div className="slide ">
                    <div className="slide_img " style={{ backgroundImage: 'url(images/slides/young-boys-posing.jpg)' }} />
                    <div className="slide__content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slide__content--headings ttm-textcolor-white text-center">
                                        <h2 data-animation="fadeInDown">"Boy's
                                            <strong>Jeans"</strong>
                                        </h2>
                                        <div className="breadcrumb-wrapper">
                                            <span>
                                                <a title="Homepage" href="index.html">Home</a>
                                            </span>
                                            <span>Boy's Jeans</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slide">
                    <div className="slide_img" style={{ backgroundImage: 'url(images/slides/young-boys-brotherhood.jpg)' }}>
                    </div>
                    <div className="slide__content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slide__content--headings ttm-textcolor-white text-center">
                                        <h2 data-animation="fadeInDown">"Boy's
                                            <strong> T Shirts"</strong>
                                        </h2>
                                        <div className="breadcrumb-wrapper">
                                            <span>
                                                <a title="Homepage" href="index.html">Home</a>
                                            </span>
                                            <span>Boy's T Shirts</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slide ">
                    <div className="slide_img " style={{ backgroundImage: 'url(images/slides/boysweater2.jpg)' }} />
                    <div className="slide__content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slide__content--headings ttm-textcolor-white text-center">
                                        <h2 data-animation="fadeInDown">"Boy's
                                            <strong>Sweaters"</strong>
                                        </h2>
                                        <div className="breadcrumb-wrapper">
                                            <span>
                                                <a title="Homepage" href="index.html">Home</a>
                                            </span>
                                            <span>Kid's Sweaters</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>

        </div>
    );
};

export default BoysApparelsBanner;