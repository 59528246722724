import React from 'react';
import AccessoriesAside from '../AccessoriesAside';
import AccessoriesBanner from '../AccessoriesBanner';
import LeatherBagItems from './LeatherBagItems';

const LeatherBag = () => {
    return (

        <div>
            <AccessoriesBanner />
            <div className="title-header section-title title-style-center_text">
                <h2 className="title font">Leather's <b> Bag's </b></h2>
            </div>
            <div className="ttm-row sidebar ttm-sidebar-left clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-4 widget-area sidebar-left side-category">
                            <AccessoriesAside />

                        </div>
                        <div className="col-lg-8 content-area example">
                            <LeatherBagItems />
                        </div>
                    </div>
                    {/* row end */}
                </div>
            </div>
        </div>

    );
};

export default LeatherBag;