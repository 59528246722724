import React from 'react';

const WomenLeggingItems = () => {
 return (
  <div>
   <div className="ttm-service-single-content-area">
    <div className="row mb_15 mt_15">
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/women/leggings/Women-Leggings-1.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Women's Leggings-1</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/women/leggings/Women-Leggings-2.jpg" alt=" image" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>{/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Women's Leggings-2</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/women/leggings/Women-Leggings-3.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Women's Leggings-3</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/women/leggings/Women-Leggings-4.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Women's Leggings-4</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/women/leggings/Women-Leggings-5.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Women's Leggings-5</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/women/leggings/Women-Leggings-6.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Women's Leggings-6</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/women/leggings/Women-Leggings-7.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Women's Leggings-7</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/women/leggings/Women-Leggings-8.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Women's Leggings-8</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
    </div>
   </div>

  </div>
 );
};

export default WomenLeggingItems;
