import React from 'react';

const BabyItems = () => {
 return (
  <div>
   <div className="ttm-service-single-content-area">
    <div className="row mb_15 mt_15">
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Bear-Little-Jacket-Set.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Bear Little Jacket Set</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Bodysuit-Dress-Cardigan-Set.jpg" alt=" image" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>{/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Bodysuit Dress Cardigan Set</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Burp-Cloths.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Burp Cloths</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Butterfly-Jersey-Tee.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Butterfly Jersey Tee</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Butterfly-Little-Cardigan-Set-768x960.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Butterfly Little Cardigan Set</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Chantal-Footie-Ruffled-Zippered-One-Piece.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Chantal Footie Ruffled Zippered One Piece</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Colored-Cars-Top-Single-Piece.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Colored Cars Top Single Piece</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Cotton-Zip-Up-Sleep-Plays.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Cotton Zip Up Sleep Plays</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Dinosaur-2-Way-Zip-Cotton-Sleep-_-Play.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Dinosaur Zip Cotton Sleep</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Donuts-Short-Sleeve-Collared-Henley-Shortall.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
        {/* ttm-box-view-overlay end*/}
        <div className="featured-content">
         <div className="featured-title">
          <h3>Donuts Short Sleeve</h3>
         </div>
        </div>
       </div>{/* featured-icon-box end*/}
      </div>
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Floral-Heart-Tee.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Floral Heart Tee</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Flower-Jersey-Tee-768x960.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Flower Jersey Tee</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Hooded-Cardigan-768x960.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Hooded Cardigan</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Posh-Pink-Ribbed-Cap-Sleeve-Ruffled-Bodysuit-Dress.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Posh Pink Ribbed Cap Sleeve Ruffled Bodysuit Dress</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Posh-Pink-Ribbed-Short-Sleeve-Pajamas.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Posh Pink Ribbed Short Sleeve Pajamas</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Powder-Blue-Ribbed-Footie-Zippered-One-Piece.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Powder Blue Ribbed Footie Zippered One Piece</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Sea-Glass-Footie-Zippered-One-Piece.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Sea Glass Footie Zippered One Piece</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Shirley-Cap-Sleeve-Ruffled-Bodysuit-Dress.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Shirley Cap Sleeve Ruffled Bodysuit Dress</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Shirley-Short-Sleeve-Short-Pajamas-768x768.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Shirley Short Sleeve Short Pajamas</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Short-Sleeve-Bodysuits.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Short Sleeve Bodysuits</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Striped-Snug-Fit-Cotton-Footie-PJs.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Striped Snug Fit Cotton Footie</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/baby/Take-Me-Home-Set.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Take Me Home Set</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
    </div>
   </div>

  </div>
 );
};

export default BabyItems;
