import React from 'react';

const HowWeDeliverSection = () => {
    return (
        <div>
            <section className="ttm-row service-section position-relative z-index-2 clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title */}
                            <div className="section-title title-style-center_text">
                                <div className="title-header">
                                    <h2 style={{ color: '#c3002f' }} className="title">How We Deliver <b>Excellence</b></h2>
                                    <p>We emphasize continuous improvement across all aspects of the
                                        business and within all
                                        business processes by creating a culture within the team where every member of
                                        an
                                        organization can see the flow of value to the customer.</p>
                                </div>
                            </div>
                            {/* section-title end */}
                        </div>
                    </div>
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row mt_0 res-991-margin_top0 mb_35">
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style3">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="fa fa-users" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3 style={{ color: '#c3002f' }}>Customers First
                                                </h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>
                                                    We put the customer's needs and preferences at the heart of our
                                                    operations.
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style3">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="fa fa-sitemap" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3 style={{ color: '#c3002f' }}>Process At Work
                                                </h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>We ensures that tasks are completed in a way that's efficient &amp;
                                                    organized.
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style3">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="fa fa-wrench" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3 style={{ color: '#c3002f' }}>Upgradation
                                                </h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>We change and moderate/improvise according to our customer’s needs.
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style3">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i class='fa fa-handshake-o'></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3 style={{ color: '#c3002f' }}>Teamwork</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>We lead our people to the best output from them to work together
                                                    toward a common goal.
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style3">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="fa fa-stack-overflow" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3 style={{ color: '#c3002f' }}>Technical Expertise
                                                </h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Our research and development team have long experience from fashion
                                                    trend forecasting and customer analysis.
                                                    .
                                                </p>
                                            </div>
                                            {/* <a class="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-skincolor"
                                          href="#">read more<i class="fa fa-long-arrow-right"></i></a> */}
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style3">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="fa fa-line-chart" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3 style={{ color: '#c3002f' }}>Growth &amp; Expansion </h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Steady leads and exceptional support fuel our clients' rapid growth
                                                    while our business expands relentlessly.
                                                </p>
                                            </div>
                                            {/* <a class="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-skincolor"
                                          href="https://www.ripongroupbd.com">read more<i
                                              class="fa fa-long-arrow-right"></i></a> */}
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* row end */}
                </div>
            </section>


        </div>
    );
};

export default HowWeDeliverSection;



