import React from 'react';
import MensApparelsBanner from '../MensApparelsBanner';
import MensAside from '../MensAside';
import MensShirtsProducts from './MensShirtsProducts';

const MensShirts = () => {
    return (
        <div>
            <MensApparelsBanner></MensApparelsBanner>
            <div class="title-header section-title title-style-center_text">
                <h2 class="title font">Men's <b>Shirts</b></h2>
            </div>
            <div className="ttm-row sidebar ttm-sidebar-left clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-4 widget-area sidebar-left side-category">
                            <MensAside></MensAside>

                        </div>
                        <div className="col-lg-8 content-area example">
                            <MensShirtsProducts></MensShirtsProducts>
                        </div>
                    </div>
                    {/* row end */}
                </div>
            </div>

        </div>
    );
};

export default MensShirts;