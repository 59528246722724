import React from 'react';
import WomenApparelBanner from '../WomenApparelBanner';
import WomenAside from '../WomenAside';
import WomenJeansItems from './WomenJeansItems';

const WomensJeans = () => {
    return (
        <div>
            <WomenApparelBanner />
            <div className="title-header section-title title-style-center_text">
                <h2 className="title font">Women's <b> Jeans </b></h2>
            </div>
            <div className="ttm-row sidebar ttm-sidebar-left clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-4 widget-area sidebar-left side-category">
                            <WomenAside />

                        </div>
                        <div className="col-lg-8 content-area example">
                            <WomenJeansItems />
                        </div>
                    </div>
                    {/* row end */}
                </div>
            </div>
        </div>
    );
};

export default WomensJeans;