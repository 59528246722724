import React, { useState } from 'react';

const ReadMore = ({ text, maxChars }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };
    const displayText = isExpanded ? text : text.slice(0, maxChars);

    return (
        <div>
            <div>
                <p>{displayText}</p>
                {text.length > maxChars && (
                    <button onClick={toggleReadMore}>
                        {isExpanded ? 'Read Less' : 'Read More'}
                    </button>
                )}
            </div>
        </div>
    );
};

export default ReadMore;