import React from 'react';

const Contact = () => {
    return (
        <div>
            <section className="ttm-row conatact-section clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-xl-5">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3649.5907373681757!2d90.4177569!3d23.8331481!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c72f4a0a3341%3A0xfe0bda08383bc596!2sEminent%20Textiles!5e0!3m2!1sen!2sbd!4v1695100372006!5m2!1sen!2sbd" width="100%" height="980px" style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='map' />
                        </div>
                        <div className="col-xl-7">
                            <div className="padding_left15 res-1199-padding_left0 padding_top20 res-1199-padding_top40">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3>CONTACT US</h3>
                                        <h2 className="title">Get In <b>Touch!</b></h2>
                                    </div>
                                    <div className="title-desc">
                                        <p>Let us know your interest…..</p>
                                    </div>
                                </div>{/* section title end */}
                                <h2 className="fs-24 padding_top10">Head Office</h2>
                                <div className="ttm-horizontal_sep width-100 margin_top20 margin_bottom5" />
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        {/*featured-icon-box*/}
                                        <div className="featured-icon-box icon-align-before-content">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                                    <i className="themifyicon ti-location-pin ttm-textcolor-skincolor" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3 className="margin_bottom0 fs-18">Address</h3>
                                                </div>
                                                <div className="featured-desc">Flat 2/A,House 04,Road 13, Nikunja 2,Dhaka
                                                    -1229, Bangladesh</div>
                                            </div>
                                        </div>{/* featured-icon-box end*/}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        {/*featured-icon-box*/}
                                        <div className="featured-icon-box icon-align-before-content">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                                    <i className="themifyicon ti-email ttm-textcolor-skincolor" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3 className="margin_bottom0 fs-18">Call Us / Email</h3>
                                                </div>
                                                <div className="featured-desc"> +8801785-678228,<br />+8801615-012668,<br />
                                                    +8801766-555756,
                                                    <br />
                                                    {/* <a href="mailto:zzoha@eminenttextiles.com">zzoha@eminenttextiles.com,
                                                    </a> */}
                                                    <a href="mailto:shadin@eminenttextiles.com">
                                                        shadin@eminenttextiles.com
                                                    </a>
                                                    <a href="mailto:shakil@eminenttextiles.co">
                                                        <br /> shakil@eminenttextiles.com</a>
                                                </div>
                                            </div>
                                        </div>{/* featured-icon-box end*/}
                                    </div>
                                </div>
                                <h2 className="fs-24 padding_top20">Branch Office</h2>
                                <div className="ttm-horizontal_sep width-100 margin_top20 margin_bottom5" />
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        {/*featured-icon-box*/}
                                        <div className="featured-icon-box icon-align-before-content">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                                    <i className="themifyicon ti-location-pin ttm-textcolor-skincolor" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3 className="margin_bottom0 fs-18">Address</h3>
                                                </div>
                                                <div className="featured-desc">90/1, KADAMTALA (Ground Floor)
                                                    Shobujbag, Dhaka-1214
                                                    Bangladesh.</div>
                                            </div>
                                        </div>{/* featured-icon-box end*/}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        {/*featured-icon-box*/}
                                        <div className="featured-icon-box icon-align-before-content">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                                    <i className="themifyicon ti-email ttm-textcolor-skincolor" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3 className="margin_bottom0 fs-18">Call Us / Email</h3>
                                                </div>
                                                <div className="featured-desc"> +8801785-678228,<br />+8801615-012668,<br />
                                                    +8801766-555756,
                                                    <br />
                                                    {/* <a href="mailto:zzoha@eminenttextiles.com">zzoha@eminenttextiles.com,
                                                    </a> */}
                                                    <a href="mailto:shadin@eminenttextiles.com">
                                                        shadin@eminenttextiles.com,
                                                    </a>
                                                    <a href="mailto:shakil@eminenttextiles.com">
                                                        <br /> shakil@eminenttextiles.com</a>
                                                </div>
                                            </div>
                                        </div>{/* featured-icon-box end*/}
                                    </div>
                                </div>
                                <h2 className="fs-24 padding_top20">Branch Office</h2>
                                <div className="ttm-horizontal_sep width-100 margin_top20 margin_bottom5" />
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        {/*featured-icon-box*/}
                                        <div className="featured-icon-box icon-align-before-content">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                                    <i className="themifyicon ti-location-pin ttm-textcolor-skincolor" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3 className="margin_bottom0 fs-18">Address</h3>
                                                </div>
                                                <div className="featured-desc">Road Name: Koning Willem-Alexanderlaan,
                                                    House No: 13,
                                                    Postcode: 2135 VL,
                                                    City: Hoofddorp ,
                                                    The Netherlands</div>
                                            </div>
                                        </div>{/* featured-icon-box end*/}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        {/*featured-icon-box*/}
                                        <div className="featured-icon-box icon-align-before-content">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                                    <i className="themifyicon ti-email ttm-textcolor-skincolor" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3 className="margin_bottom0 fs-18">Call Us / Email</h3>
                                                </div>
                                                <div className="featured-desc"> +31683636609,
                                                    <br /> <a href="mailto:hasib@eminenttextiles.com">hasib@eminenttextiles.com</a>
                                                </div>
                                            </div>
                                        </div>{/* featured-icon-box end*/}
                                    </div>
                                </div>
                                <h2 className="fs-24 padding_top20">Branch Office</h2>
                                <div className="ttm-horizontal_sep width-100 margin_top20 margin_bottom5" />
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        {/*featured-icon-box*/}
                                        <div className="featured-icon-box icon-align-before-content">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                                    <i className="themifyicon ti-location-pin ttm-textcolor-skincolor" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3 className="margin_bottom0 fs-18">Address</h3>
                                                </div>
                                                <div className="featured-desc">RB-77, Royal Park , 1st
                                                    Palladam Road , Tirupur-641605, India</div>
                                            </div>
                                        </div>{/* featured-icon-box end*/}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        {/*featured-icon-box*/}
                                        <div className="featured-icon-box icon-align-before-content">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                                    <i className="themifyicon ti-email ttm-textcolor-skincolor" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3 className="margin_bottom0 fs-18">Call Us / Email</h3>
                                                </div>
                                                <div className="featured-desc"> +91 9626232999
                                                    <br /> <a href="mailto:s.kumar@eminenttextiles.com">s.kumar@eminenttextiles.com</a>
                                                </div>
                                            </div>
                                        </div>{/* featured-icon-box end*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>

        </div>
    );
};

export default Contact;
