import React from 'react';
import BoysApparelsBanner from '../BoysApparelsBanner';
import BoysAside from '../BoysAside';
import BoysJeansItem from './BoysJeansItem';

const BoysJeans = () => {
    return (
        <div>
            <BoysApparelsBanner />
            <div className="title-header section-title title-style-center_text">
                <h2 className="title font">Boy's <b> Jeans </b></h2>
            </div>
            <div className="ttm-row sidebar ttm-sidebar-left clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-4 widget-area sidebar-left side-category">
                            <BoysAside />

                        </div>
                        <div className="col-lg-8 content-area example">
                            <BoysJeansItem />
                        </div>
                    </div>
                    {/* row end */}
                </div>
            </div>
        </div>
    );
};

export default BoysJeans;