import React from 'react';
import Slider from 'react-slick';

const WomenApparelBanner = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,

    }
    return (
        <div>
            <Slider className="banner_slider " {...settings}>
                <div className="slide ">
                    <div className="slide_img " style={{ backgroundImage: 'url(images/slides/women3.jpg)' }} />
                    <div className="slide__content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slide__content--headings ttm-textcolor-white text-center">
                                        <h2 data-animation="fadeInDown">"Women's
                                            <strong>Jeans"</strong>
                                        </h2>
                                        <div className="breadcrumb-wrapper">
                                            <span>
                                                <a title="Homepage" href="index.html">Home</a>
                                            </span>
                                            <span>Women's Jeans</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slide">
                    <div className="slide_img" style={{ backgroundImage: 'url(images/slides/women.jpg)' }}>
                    </div>
                    <div className="slide__content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slide__content--headings ttm-textcolor-white text-center">
                                        <h2 data-animation="fadeInDown">"Women's
                                            <strong> T Shirts"</strong>
                                        </h2>
                                        <div className="breadcrumb-wrapper">
                                            <span>
                                                <a title="Homepage" href="index.html">Home</a>
                                            </span>
                                            <span>Women's T Shirts</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slide ">
                    <div className="slide_img " style={{ backgroundImage: 'url(images/slides/women1.jpg)' }} />
                    <div className="slide__content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slide__content--headings ttm-textcolor-white text-center">
                                        <h2 data-animation="fadeInDown">"Women's
                                            <strong>Sweaters"</strong>
                                        </h2>
                                        <div className="breadcrumb-wrapper">
                                            <span>
                                                <a title="Homepage" href="index.html">Home</a>
                                            </span>
                                            <span>Women's Sweaters</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>

        </div>
    );
};

export default WomenApparelBanner;