import React from 'react';

const HandicraftItems = () => {
 return (
  <div>
   <div className="ttm-service-single-content-area">
    <div className="row mb_15 mt_15">
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/accesories/bag.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Bag-1</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/accesories/bag1.jpg" alt=" image" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>{/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Bag-2</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/accesories/bag3.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Bag-3</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/accesories/bag4.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Bag-4</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/accesories/bag6.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Bag-5</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/accesories/bag7.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Bag-6</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/accesories/bag8.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Bag-7</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/accesories/bag9.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Bag-8</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/accesories/bag5.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Bag-9</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/accesories/ladiesbag1.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Bag-10</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/accesories/ladiesbag2.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Bag-11</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/accesories/ladies5.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Bag-12</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
     <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio style1">
       {/* ttm-box-view-overlay */}
       <div className="ttm-box-view-overlay">
        {/* featured-thumbnail */}
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/accesories/ladiesbag.jpg" alt="" />
        </div>{/* featured-thumbnail end*/}
        <div className="ttm-media-link">
         <a href="/contact">
          <i className="fa fa-search" />
         </a>
        </div>
       </div>
       {/* ttm-box-view-overlay end*/}
       <div className="featured-content">
        <div className="featured-title">
         <h3>Bag-13</h3>
        </div>
       </div>
      </div>{/* featured-icon-box end*/}
     </div>
    </div>
   </div>

  </div>
 );
};

export default HandicraftItems;
