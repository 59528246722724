import React from 'react';

const LifeWithUsOurPartners = () => {
    return (
        <div>
            <section className="ttm-row about-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-xs-12">
                            <div className="padding_top20 res-991-padding_top40">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3 style={{ fontSize: 30 }}>Our Partners</h3>
                                    </div>
                                    <div className="title-desc">
                                        <p>Our journey has been made smoother, more exciting, and more rewarding because
                                            of our partners. They have stood by our side through thick and thin, sharing
                                            our vision and helping us achieve our goals.
                                            These partnerships are not just about business; they are about shared
                                            values, shared aspirations, and a shared commitment to making a positive
                                            impact on our community and the world at large.
                                            Through these collaborations, we have been able to innovate, grow, and make
                                            a difference. Our partners have brought unique perspectives, expertise, and
                                            resources to the table, allowing us to reach new heights and tackle
                                            challenges that seemed insurmountable on our own.
                                        </p>
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="ttm_single_image-wrapper text-left">
                                <img style={{ width: 600, height: 400 }} className="img-fluid" src="images/slides/handshake_190617.jpg" alt="single-09" />
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>

        </div>
    );
};

export default LifeWithUsOurPartners;