import React from 'react';
import AccessoriesAside from '../AccessoriesAside';
import AccessoriesBanner from '../AccessoriesBanner';
import LeatherShoesItems from './LeatherShoesItems';

const LeatherShoes = () => {
    return (

        <div>
            <AccessoriesBanner />
            <div className="title-header section-title title-style-center_text">
                <h2 className="title font">Leather's <b> Belt & Shoe's </b></h2>
            </div>
            <div className="ttm-row sidebar ttm-sidebar-left clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-4 widget-area sidebar-left side-category">
                            <AccessoriesAside />

                        </div>
                        <div className="col-lg-8 content-area example">
                            <LeatherShoesItems />
                        </div>
                    </div>
                    {/* row end */}
                </div>
            </div>
        </div>

    );
};

export default LeatherShoes;