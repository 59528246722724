// import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
// import Contact from './Pages/ContactUs';
// import Home from './Pages/Home';
import React from 'react';
// import LifeWithUs from './Pages/LifeWithUs';
// import Navbar from './Components/Navbar';
// import NoteState from './Context/Notes/NoteState';
// import Products from './Pages/Products';


function App() {
  return (
    <></>
    // <NoteState>
    //   <BrowserRouter>
    //     <Navbar></Navbar>
    //     <Routes>
    //       <Route path='/' element={<Home></Home>}></Route>
    //       {/* <Route path='/products' element={<Products />}></Route> */}
    //       <Route path='/lifewithus' element={<LifeWithUs></LifeWithUs>}></Route>
    //       <Route path='/contact' element={<Contact></Contact>}></Route>
    //     </Routes>
    //   </BrowserRouter>
    // </NoteState>
  );
}

export default App;
