import React from 'react';

const OurVision = () => {
    return (
        <div>
            <section className="ttm-row about-section ttm-bgcolor-grey clearfix">
                <div className="container">
                    <div className="row">
                        <div style={{ marginTop: 50, textAlign: 'right' }} className="col-lg-6 col-md-12">
                            <div className="padding_top20 res-991-padding_top40">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3 style={{ fontSize: 30 }}>Our Vision</h3>
                                    </div>
                                    <div className="title-desc">
                                        <p>Our vision is to anchored in People, Passion, and Partnership.
                                            We are on our way to make Eminent Textiles one of Bangladesh’s top three
                                            apparel sourcing agent within the next few years.
                                        </p>
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xs-12">
                            <div className="ttm_single_image-wrapper text-left">
                                <img style={{ width: 500, height: 300 }} className="img-fluid" src="images/aboutus/vision.jpg" alt="single-09" />
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>

        </div>
    );
};

export default OurVision;