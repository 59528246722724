import React from 'react';

const OurValues = () => {
    return (
        <div>
            <section className="ttm-row about-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="ttm_single_image-wrapper text-left">
                                <img style={{ width: 600, height: 400 }} className="img-fluid" src="images/aboutus/values.jpg" alt="single-09" />
                            </div>
                        </div>
                        <div style={{ marginTop: 40 }} className="col-lg-6 col-md-12 col-xs-12">
                            <div className="padding_top20 res-991-padding_top40">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3 style={{ fontSize: 30 }}> Our Values</h3>
                                    </div>
                                    <div className="title-desc">
                                        <p>We believe in great teamwork and positive attitude which leads us to growth
                                            together.
                                            Our good relevant product knowledge and understanding benefits is much more
                                            compelling.
                                            Our people have a broad knowledge of the wide range of product, aptitude and
                                            confidence to deliver the right product with the required quality in a given
                                            time.
                                            Our technical experts have the ability and knowledge to pose insightful and
                                            engaging questions in the beginning of order placement and excellent skills
                                            to solve the problem in process.
                                            Our excellent communication and rapport-building skills and the ability to
                                            handle the day to day questions/queries that will inevitably come up from
                                            the customers and the manufacturing units.
                                        </p>
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>

        </div>
    );
};

export default OurValues;