import React from 'react';

const Blogs = () => {
 return (
  <div>
   <section className="ttm-row grid-section clearfix">
    <div className="container">
     {/* row */}
     <div className="row">
      <div className="col-lg-12">
       {/* section title */}
       {/* <div class="section-title title-style-center_text">
                          <div class="title-header">
                              <h2 class="title"><b>Our </b>Blog's</h2>
                          </div>
                      </div> */}
       {/* section title end */}
      </div>
     </div>
     <div className="row mb_15">
      <div className="col-lg-4">
       {/* featured-imagebox-post */}
       <div className="featured-imagebox featured-imagebox-post style1">
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/blog/b_thumbb-02.jpg" alt="" />
        </div>
        <div className="featured-content">
         {/* ttm-box-post-date */}
         <div className="ttm-box-post-date">
          <span className="ttm-entry-date">
           <time className="entry-date" dateTime="2021-03-18T04:16:25+00:00">18<span className="entry-month entry-year">Mar</span></time>
          </span>
         </div>{/* ttm-box-post-date end */}
         <div className="post-meta">
          <span className="ttm-meta-line byline">John Doe</span>
          <span className="ttm-meta-line comments-link">0 Comments</span>
         </div>
         <div className="featured-title">
          <h3><a href="blog-single.html">Can textiles lead the way during the
           pandemic?</a></h3>
         </div>
         <div className="featured-desc">
          <p>These cases are perfectly simple and easy to distinguish. In a free hour,
           when our po...</p>
         </div>
         <a className="ttm-btn ttm-btn-size-md ttm-btn-color-dark btn-inline" href="blog-single.html" tabIndex={-1}>read more</a>
        </div>
       </div>{/* featured-imagebox-post end */}
      </div>
      <div className="col-lg-4">
       {/* featured-imagebox-post */}
       <div className="featured-imagebox featured-imagebox-post style1">
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/blog/b_thumbb-01.jpg" alt="" />
        </div>
        <div className="featured-content">
         {/* ttm-box-post-date */}
         <div className="ttm-box-post-date">
          <span className="ttm-entry-date">
           <time className="entry-date" dateTime="2021-02-28T04:15:42+00:00">28<span className="entry-month entry-year">Feb</span></time>
          </span>
         </div>
         <div className="post-meta">
          <span className="ttm-meta-line byline">John Doe</span>
          <span className="ttm-meta-line comments-link">0 Comments</span>
         </div>{/* post-meta end */}
         <div className="featured-title">
          <h3><a href="blog-single.html">One stop solution for textiles fabrics</a></h3>
         </div>
         <div className="featured-desc">
          <p>Purus non enim praesent elementum facilisis. Eget mi proin sed libero enim
           sed faucib...</p>
         </div>
         <a className="ttm-btn ttm-btn-size-md ttm-btn-color-dark btn-inline" href="blog-single.html">read more</a>
        </div>
       </div>{/* featured-imagebox-post end */}
      </div>
      <div className="col-lg-4">
       {/* featured-imagebox-post */}
       <div className="featured-imagebox featured-imagebox-post style1">
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/blog/b_thumbb-03.jpg" alt="" />
        </div>
        <div className="featured-content">
         {/* ttm-box-post-date */}
         <div className="ttm-box-post-date">
          <span className="ttm-entry-date">
           <time className="entry-date" dateTime="2021-02-15T04:15:25+00:00">15<span className="entry-month entry-year">Feb</span></time>
          </span>
         </div>{/* ttm-box-post-date end */}
         <div className="post-meta">
          <span className="ttm-meta-line byline">John Doe</span>
          <span className="ttm-meta-line comments-link">0 Comments</span>
         </div>{/* post-meta end */}
         <div className="featured-title">
          <h3><a href="blog-single.html">Textilegence Magazine &amp; Digital Platform</a></h3>
         </div>
         <div className="featured-desc">
          <p>Enim nunc faucibus a pellentesque sit amet porttitor eget. Morbi blandit
           cursus risus...</p>
         </div>
         <a className="ttm-btn ttm-btn-size-md ttm-btn-color-dark btn-inline" href="blog-single.html" tabIndex={-1}>read more</a>
        </div>
       </div>{/* featured-imagebox-post end */}
      </div>
      <div className="col-lg-4">
       {/* featured-imagebox-post */}
       <div className="featured-imagebox featured-imagebox-post style1">
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/blog/b_thumbb-02.jpg" alt="" />
        </div>
        <div className="featured-content">
         {/* ttm-box-post-date */}
         <div className="ttm-box-post-date">
          <span className="ttm-entry-date">
           <time className="entry-date" dateTime="2021-02-15T04:15:25+00:00">15<span className="entry-month entry-year">Feb</span></time>
          </span>
         </div>{/* ttm-box-post-date end */}
         <div className="post-meta">
          <span className="ttm-meta-line byline">John Doe</span>
          <span className="ttm-meta-line comments-link">0 Comments</span>
         </div>{/* post-meta end */}
         <div className="featured-title">
          <h3><a href="blog-single.html">Textilegence Magazine &amp; Digital Platform</a></h3>
         </div>
         <div className="featured-desc">
          <p>Enim nunc faucibus a pellentesque sit amet porttitor eget. Morbi blandit
           cursus risus...</p>
         </div>
         <a className="ttm-btn ttm-btn-size-md ttm-btn-color-dark btn-inline" href="blog-single.html" tabIndex={-1}>read more</a>
        </div>
       </div>{/* featured-imagebox-post end */}
      </div>
      <div className="col-lg-4">
       {/* featured-imagebox-post */}
       <div className="featured-imagebox featured-imagebox-post style1">
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/blog/b_thumbb-02.jpg" alt="" />
        </div>
        <div className="featured-content">
         {/* ttm-box-post-date */}
         <div className="ttm-box-post-date">
          <span className="ttm-entry-date">
           <time className="entry-date" dateTime="2021-02-15T04:15:25+00:00">15<span className="entry-month entry-year">Feb</span></time>
          </span>
         </div>{/* ttm-box-post-date end */}
         <div className="post-meta">
          <span className="ttm-meta-line byline">John Doe</span>
          <span className="ttm-meta-line comments-link">0 Comments</span>
         </div>{/* post-meta end */}
         <div className="featured-title">
          <h3><a href="blog-single.html">Textilegence Magazine &amp; Digital Platform</a></h3>
         </div>
         <div className="featured-desc">
          <p>Enim nunc faucibus a pellentesque sit amet porttitor eget. Morbi blandit
           cursus risus...</p>
         </div>
         <a className="ttm-btn ttm-btn-size-md ttm-btn-color-dark btn-inline" href="blog-single.html" tabIndex={-1}>read more</a>
        </div>
       </div>{/* featured-imagebox-post end */}
      </div>
      <div className="col-lg-4">
       {/* featured-imagebox-post */}
       <div className="featured-imagebox featured-imagebox-post style1">
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/blog/b_thumbb-03.jpg" alt="" />
        </div>
        <div className="featured-content">
         {/* ttm-box-post-date */}
         <div className="ttm-box-post-date">
          <span className="ttm-entry-date">
           <time className="entry-date" dateTime="2021-02-15T04:15:25+00:00">15<span className="entry-month entry-year">Feb</span></time>
          </span>
         </div>{/* ttm-box-post-date end */}
         <div className="post-meta">
          <span className="ttm-meta-line byline">John Doe</span>
          <span className="ttm-meta-line comments-link">0 Comments</span>
         </div>{/* post-meta end */}
         <div className="featured-title">
          <h3><a href="blog-single.html">Textilegence Magazine &amp; Digital Platform</a></h3>
         </div>
         <div className="featured-desc">
          <p>Enim nunc faucibus a pellentesque sit amet porttitor eget. Morbi blandit
           cursus risus...</p>
         </div>
         <a className="ttm-btn ttm-btn-size-md ttm-btn-color-dark btn-inline" href="blog-single.html" tabIndex={-1}>read more</a>
        </div>
       </div>{/* featured-imagebox-post end */}
      </div>
      <div className="col-lg-4">
       {/* featured-imagebox-post */}
       <div className="featured-imagebox featured-imagebox-post style1">
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/blog/b_thumbb-01.jpg" alt="" />
        </div>
        <div className="featured-content">
         {/* ttm-box-post-date */}
         <div className="ttm-box-post-date">
          <span className="ttm-entry-date">
           <time className="entry-date" dateTime="2021-02-15T04:15:25+00:00">15<span className="entry-month entry-year">Feb</span></time>
          </span>
         </div>{/* ttm-box-post-date end */}
         <div className="post-meta">
          <span className="ttm-meta-line byline">John Doe</span>
          <span className="ttm-meta-line comments-link">0 Comments</span>
         </div>{/* post-meta end */}
         <div className="featured-title">
          <h3><a href="blog-single.html">Textilegence Magazine &amp; Digital Platform</a></h3>
         </div>
         <div className="featured-desc">
          <p>Enim nunc faucibus a pellentesque sit amet porttitor eget. Morbi blandit
           cursus risus...</p>
         </div>
         <a className="ttm-btn ttm-btn-size-md ttm-btn-color-dark btn-inline" href="blog-single.html" tabIndex={-1}>read more</a>
        </div>
       </div>{/* featured-imagebox-post end */}
      </div>
      <div className="col-lg-4">
       {/* featured-imagebox-post */}
       <div className="featured-imagebox featured-imagebox-post style1">
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/blog/b_thumbb-01.jpg" alt="" />
        </div>
        <div className="featured-content">
         {/* ttm-box-post-date */}
         <div className="ttm-box-post-date">
          <span className="ttm-entry-date">
           <time className="entry-date" dateTime="2021-02-15T04:15:25+00:00">15<span className="entry-month entry-year">Feb</span></time>
          </span>
         </div>{/* ttm-box-post-date end */}
         <div className="post-meta">
          <span className="ttm-meta-line byline">John Doe</span>
          <span className="ttm-meta-line comments-link">0 Comments</span>
         </div>{/* post-meta end */}
         <div className="featured-title">
          <h3><a href="blog-single.html">Textilegence Magazine &amp; Digital Platform</a></h3>
         </div>
         <div className="featured-desc">
          <p>Enim nunc faucibus a pellentesque sit amet porttitor eget. Morbi blandit
           cursus risus...</p>
         </div>
         <a className="ttm-btn ttm-btn-size-md ttm-btn-color-dark btn-inline" href="blog-single.html" tabIndex={-1}>read more</a>
        </div>
       </div>{/* featured-imagebox-post end */}
      </div>
      <div className="col-lg-4">
       {/* featured-imagebox-post */}
       <div className="featured-imagebox featured-imagebox-post style1">
        <div className="featured-thumbnail">
         <img className="img-fluid" src="images/blog/b_thumbb-03.jpg" alt="" />
        </div>
        <div className="featured-content">
         {/* ttm-box-post-date */}
         <div className="ttm-box-post-date">
          <span className="ttm-entry-date">
           <time className="entry-date" dateTime="2021-02-15T04:15:25+00:00">15<span className="entry-month entry-year">Feb</span></time>
          </span>
         </div>{/* ttm-box-post-date end */}
         <div className="post-meta">
          <span className="ttm-meta-line byline">John Doe</span>
          <span className="ttm-meta-line comments-link">0 Comments</span>
         </div>{/* post-meta end */}
         <div className="featured-title">
          <h3><a href="blog-single.html">Textilegence Magazine &amp; Digital Platform</a></h3>
         </div>
         <div className="featured-desc">
          <p>Enim nunc faucibus a pellentesque sit amet porttitor eget. Morbi blandit
           cursus risus...</p>
         </div>
         <a className="ttm-btn ttm-btn-size-md ttm-btn-color-dark btn-inline" href="blog-single.html" tabIndex={-1}>read more</a>
        </div>
       </div>{/* featured-imagebox-post end */}
      </div>
     </div>
    </div>
   </section>

  </div>
 );
};

export default Blogs;
