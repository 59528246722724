import React from 'react';
import MensAside from '../MensAside';
import MensJeansPant from './MensJeansPant';

const MensMain = () => {
    return (
        <div>
            <div>
                <div className="title-header section-title title-style-center_text">
                    <h2 className="title font">Men's <b>Jeans</b></h2>
                </div>
                <div className="ttm-row sidebar ttm-sidebar-left clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-4 widget-area sidebar-left side-category">
                                <MensAside></MensAside>

                            </div>
                            <div className="col-lg-8 content-area example">
                                <MensJeansPant></MensJeansPant>
                            </div>
                        </div>
                        {/* row end */}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default MensMain;