import React from 'react';
import Slider from 'react-slick';


const HomeBanner = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,

    }
    return (
        <div >
            <div className="">
                <Slider className="banner_slider " {...settings}>
                    <div className="slide" >
                        <div className="slide_img" style={{ backgroundImage: 'url(images/slides/group1.jpg)' }}>
                        </div>
                        <div className="slide__content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="slide__content--headings ttm-textcolor-white text-center">
                                            <h3 data-animation="fadeInDown">WELCOME TO EMINENT </h3>
                                            <h2 data-animation="fadeInDown">"We provide a one stop solution of fashion
                                                <strong>production in Bangladesh
                                                    "</strong>
                                            </h2>
                                            {/* <p  data-animation="fadeInDown">Since more than 40 years, EMINENT is operating in the textile market manufacture <br>and trader. As years go by, the range of produced.</p> */}
                                            <div className="d-inline-block margin_top30 res-767-margin_top25" data-animation="fadeInUp" data-delay="1.4">
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor" href="aboutus.html">more detail</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide ">
                        <div className="slide_img " style={{ backgroundImage: 'url(images/slides/bg02.jpg)' }} />
                        <div className="slide__content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="slide__content--headings ttm-textcolor-white text-center">
                                            <h3 data-animation="fadeInDown">Your Trusted Agent </h3>
                                            <h2 data-animation="fadeInDown">"Your trusted partner for sustainable
                                                <strong>apparel product development</strong>
                                                and global sourcing strategies,<strong>combined with quality and
                                                    compliance."</strong>
                                            </h2>
                                            {/* <p  data-animation="fadeInDown">Since more than 40 years, Eminent is operating in the textile market manufacture <br>and trader. As years go by, the range of produced.</p> */}
                                            <div className="d-inline-block margin_top30 res-767-margin_top20" data-animation="fadeInUp" data-delay="1.4">
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor margin_right15" href="aboutus.html">more details</a>
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-white" href="contact-us.html">get a quote</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
};

export default HomeBanner;