import React from 'react';
import Client from '../Components/Client';
import ComplienceSection from '../Components/Home/ComplienceSection';
import HomeBanner from '../Components/Home/HomeBanner';
import HomeTestimonialSection from '../Components/Home/HomeTestimonialSection';
import HowWeDeliverSection from '../Components/Home/HowWeDeliverSection';
import OurSolutionSection from '../Components/Home/OurSolutionSection';
import WhoWeAreSection from '../Components/Home/WhoWeAreSection';
import WhyClientChooseUsSection from '../Components/Home/WhyClientChooseUsSection';



const Home = () => {

    return (
        <div>

            <HomeBanner></HomeBanner>
            <WhoWeAreSection></WhoWeAreSection>
            <OurSolutionSection></OurSolutionSection>
            <HowWeDeliverSection></HowWeDeliverSection>
            <WhyClientChooseUsSection></WhyClientChooseUsSection>
            <ComplienceSection></ComplienceSection>
            <HomeTestimonialSection></HomeTestimonialSection>
            <Client></Client>

        </div>

    );
}


export default Home;