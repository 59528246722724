import React from 'react';

const OurMission = () => {
    return (
        <div>
            <section className="ttm-row about-section  clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="ttm_single_image-wrapper text-left">
                                <img style={{ width: 600, height: 300 }} className="img-fluid" src="images/aboutus/mission.jpg" alt="single-09" />
                            </div>
                        </div>
                        <div style={{ marginTop: 50 }} className="col-lg-6 col-md-12 col-xs-12 ">
                            <div className="padding_top20 res-991-padding_top40">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3 style={{ fontSize: 30 }}> Our Mission</h3>
                                    </div>
                                    <div className="title-desc">
                                        <p>To enhance the lives of our customers by simply listening to their needs and
                                            adapting to the changing markets as customer satisfaction is at the heart of
                                            everything we do. Our vision is to maximize the sale with complete customer
                                            satisfaction with impartiality and compassion.
                                        </p>
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>

        </div>
    );
};

export default OurMission;