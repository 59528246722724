import React from 'react';

const LifeWithUsMain = () => {
    return (
        <div>
            <section className="ttm-row about-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-xs-12">
                            <div className="ttm_single_image-wrapper text-left">
                                <img style={{ width: 600, height: 400 }} className="img-fluid" src="images/quality/textile.jpg" alt="single-09" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="padding_top20 res-991-padding_top40">
                                {/* section title */}
                                <div className="section-title">
                                    {/* <div class="title-header">
                                  <h3 style="font-size:30px;">Our Partners</h3>

                              </div> */}
                                    <div className="title-desc">
                                        <p>Eminent Textiles is a leading trader of Woven &amp;
                                            Knitted Fashion Apparel for UK/ USA/ UAE/
                                            European retailers. Supported by highly skilled design
                                            &amp; production team and exposure of supplying major
                                            retailers from China and Vietnam, this company has the
                                            ability to provide a wide variety of product of
                                            outrageous standard. Our expertise in design &amp;
                                            logistics enables us to produce high quality service and
                                            complete solution for Men, Women &amp; Kids wear in a
                                            competitive price range.
                                            Eminent Textiles®
                                            Eminent Textiles is a leading trader of Woven &amp;
                                            Knitted Fashion Apparel for UK/ USA/ UAE/
                                            European retailers. Supported by highly skilled
                                            designer &amp; production team and exposure of
                                            supplying major retailers across the globe, this
                                            company has the ability to provide a wide variety
                                            of product of outrageous standard. Our expertise
                                            in design &amp; logistics enables us to produce high
                                            quality service and complete solution for Men,
                                            Women &amp; Kids wear in a competitive price range.
                                        </p>
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>

        </div>
    );
};

export default LifeWithUsMain;