import React from 'react';

const Footer = () => {
    return (
        <div>
            <footer className="footer widget-footer ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
                <div className="second-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 widget-area">
                                <div className="widget widget_social clearfix">
                                    <div className="social-icons text-lg-left">
                                        <ul className="social-icons list-inline">
                                            <li><a className="tooltip-top" href="#" rel="noopener" aria-label="facebook" data-tooltip="Facebook"><i className="fa fa-facebook" /></a></li>
                                            <li><a className="tooltip-top" href="#" rel="noopener" aria-label="twitter" data-tooltip="Twitter"><i className="fa fa-twitter" /></a></li>
                                            <li><a className="tooltip-top" href="#" rel="noopener" aria-label="flickr" data-tooltip="Flickr"><i className="fa fa-flickr" /></a></li>
                                            <li><a className="tooltip-top" href="#" rel="noopener" aria-label="linkedin" data-tooltip="Linkedin"><i className="fa fa-linkedin" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="widget widget_text margin_right10 clearfix">
                                    <h3 className="widget-title">Trail Run </h3>
                                    <div className="textwidget widget-text">
                                        <div className="call_detail">
                                            <h3 className="fs-24">+8801766-555756</h3>
                                            <div className="padding_top10 padding_bottom10">
                                                <p>Our online scheduling and payment system is safe.</p>
                                            </div>
                                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-white w-100 text-center" href="/contact">Request Online Form</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 widget-area">
                                <div className="widget widget-recent-post clearfix">
                                    <h3 className="widget-title">Regional Office-1 </h3>
                                    <ul className="widget_contact_wrapper">
                                        <li>
                                            <i className="ttm-textcolor-skincolor fa fa-map-marker" />Road Name: Koning
                                            Willem-Alexanderlaan, House No: 13,
                                            Postcode: 2135 VL, City: Hoofddorp ,
                                            Netherlands
                                        </li>
                                        <li>
                                            <i className="ttm-textcolor-skincolor fa fa-phone" />+31683636609
                                        </li>
                                        <li><i className="ttm-textcolor-skincolor fa fa-envelope-o" /><a href="mailto:hasib@eminenttextiles.com">hasib@eminenttextiles.com
                                        </a></li>
                                    </ul>
                                    <br />
                                    <h3 className="widget-title">Regional Office-2 </h3>
                                    <ul className="widget_contact_wrapper">
                                        <li>
                                            <i className="ttm-textcolor-skincolor fa fa-map-marker" />RB-77, Royal Park , 1st
                                            Palladam Road , Tirupur-641605, India
                                        </li>
                                        <li>
                                            <i className="ttm-textcolor-skincolor fa fa-phone" />+91 9626232999
                                        </li>
                                        <li><i className="ttm-textcolor-skincolor fa fa-envelope-o" /><a href="mailto:s.kumar@eminenttextiles.com">s.kumar@eminenttextiles.com
                                        </a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 widget-area">
                                <div className="widget widget-recent-post clearfix">
                                    <h3 className="widget-title">Branch Office </h3>
                                    <ul className="widget_contact_wrapper">
                                        <li>
                                            <i className="ttm-textcolor-skincolor fa fa-map-marker" />90/1, KADAMTALA (Ground
                                            Floor) Shobujbag, Dhaka-1214 Bangladesh.
                                        </li>
                                        <li><i className="ttm-textcolor-skincolor fa fa-phone" />+8801785-678228,
                                            <br />+8801615-012668,<br />
                                            +8801766-555756
                                        </li>
                                        <li>
                                            <i className="ttm-textcolor-skincolor fa fa-envelope-o" />
                                            <a href="mailto:shadin@eminenttextiles.com">
                                                shadin@eminenttextiles.com,
                                            </a>
                                            <a href="mailto:shakil@eminenttextiles.co">
                                                <br /> shakil@eminenttextiles.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 widget-area">
                                <div className="widget widget-latest-tweets clearfix">
                                    <h3 className="widget-title">Corporate Office</h3>
                                    <ul className="widget_contact_wrapper">
                                        <li>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3649.5907373681757!2d90.4177569!3d23.8331481!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c72f4a0a3341%3A0xfe0bda08383bc596!2sEminent%20Textiles!5e0!3m2!1sen!2sbd!4v1695100372006!5m2!1sen!2sbd" width="100%" height="100px" style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" /><i className="ttm-textcolor-skincolor fa fa-map-marker">
                                            </i>Flat 2/A,House 04,Road
                                            13,
                                            Nikunja 2,Dhaka -1229, Bangladesh
                                        </li>
                                        <li><i className="ttm-textcolor-skincolor fa fa-phone" />+8801785-678228,
                                            <br />+8801615-012668,<br />
                                            +8801766-555756
                                        </li>
                                        <li><i className="ttm-textcolor-skincolor fa fa-envelope-o" />
                                            {/* <a href="mailto:zzoha@eminenttextiles.com">zzoha@eminenttextiles.com,
                                        </a> */}
                                            <a href="mailto:shadin@eminenttextiles.com">
                                                shadin@eminenttextiles.com,
                                            </a>
                                            <a href="mailto:shakil@eminenttextiles.co">
                                                <br /> shakil@eminenttextiles.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 widget-area">
                                <div className="widget newsletter_widget clearfix">
                                    <form id="subscribe-form" className="newsletter-form" method="post" action="#" data-mailchimp="true">
                                        <div className="mailchimp-inputbox clearfix" id="subscribe-content">
                                            <input type="text" name="NAME" id="txtname" placeholder="Your Name *" required />
                                            <input type="email" name="EMAIL" id="txtemail" placeholder="Your Email *" required />
                                            <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-fill ttm-btn-color-skincolor" type="submit">Subscribe us<i className="fa fa-caret-right" aria-hidden="true" /></button>
                                        </div>
                                        <div id="subscribe-msg" />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer-text copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <span className="cpy-text">Copyright © 2023 <a href="#" className="ttm-textcolor-skincolor font-weight-500">Eminent Textiles </a> All rights
                                        reserved.</span>
                                    <ul className="footer-nav-menu">
                                        <li><a href="/aboutus">About Us</a></li>
                                        <li><a href="/contact">Contact Us</a></li>
                                    </ul>
                                    <span className="cpy-text">Developed by  <a href="https://www.etlimited.net/" className="ttm-textcolor-skincolor font-weight-500">ET Tech Limited</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    );
};

export default Footer;




