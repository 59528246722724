import React from 'react';
import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const HomeTestimonialSection = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,

    }
    return (
        <div>
            <section className="ttm-row testimonial-section bg-img2 ttm-bg ttm-bgimage-yes clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="padding_top70 res-991-padding_top0" />
                            {/* section-title */}
                            <div className="section-title title-style-center_text">
                                <div className="title-header">
                                    <h3>TESTIMONIALS</h3>
                                    <h2 className="title">Our Customers <b>Are Saying?</b></h2>
                                </div>
                            </div>{/* section-title end */}
                        </div>
                    </div>{/* row end */}
                    <div className="row">
                        <div className="col-xl-9 col-lg-10 m-auto">
                            <Slider {...settings} className="promotions-carousel1 slick-arrows-style1" >
                                {/* testimonials */}
                                <div className="testimonials ttm-testimonial-box-view-style1">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/testimonial/01.jpg" alt="testimonial-img" />
                                        </div>
                                    </div>
                                    <div className="testimonial-content">
                                        <blockquote className="testimonial-text">Eminent's customer service is great. I  was
                                            searching &amp; found exactly what I wanted, was delivered quickly. Very good
                                            transaction. Fast shipping. Great blankets fabric and I am planning to buy it in
                                            bulk order.</blockquote>
                                        <div className="testimonial-bottom">
                                            <div className="star-ratings">
                                                <ul className="rating">
                                                    <li className="active"><i className="fa fa-star" /></li>
                                                    <li className="active"><i className="fa fa-star" /></li>
                                                    <li className="active"><i className="fa fa-star" /></li>
                                                    <li className="active"><i className="fa fa-star" /></li>
                                                    <li className="active"><i className="fa fa-star" /></li>
                                                </ul>
                                            </div>
                                            <div className="testimonial-caption">
                                                <h5>Michal Wincent</h5>
                                                <label>Customer</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>{/* testimonials end */}
                                {/* testimonials */}
                                <div className="testimonials ttm-testimonial-box-view-style1">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/testimonial/02.jpg" alt="testimonial-img" />
                                        </div>
                                    </div>
                                    <div className="testimonial-content">
                                        <blockquote className="testimonial-text">The culture is the best at Eminent. You
                                            can
                                            tell that they truly care about the happiness of their employees. It's a
                                            really
                                            relaxed and calm environment and their quality of product is always
                                            best. I
                                            refer them for the future.</blockquote>
                                        <div className="testimonial-bottom">
                                            <div className="star-ratings">
                                                <ul className="rating">
                                                    <li className="active"><i className="fa fa-star" /></li>
                                                    <li className="active"><i className="fa fa-star" /></li>
                                                    <li className="active"><i className="fa fa-star" /></li>
                                                    <li className="active"><i className="fa fa-star" /></li>
                                                    <li className="active"><i className="fa fa-star" /></li>
                                                </ul>
                                            </div>
                                            <div className="testimonial-caption">
                                                <h5>Elic Semules</h5>
                                                <label>Manager</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>{/* testimonials end */}
                                {/* testimonials */}
                                <div className="testimonials ttm-testimonial-box-view-style1">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/testimonial/03.jpg" alt="testimonial-img" />
                                        </div>
                                    </div>
                                    <div className="testimonial-content">
                                        <blockquote className="testimonial-text">I realized after I sent in my order
                                            that I
                                            had
                                            ordered a larger quantity than I needed. I emailed and received a quick
                                            response, adjustment to my order and a refund. Product was shipped out
                                            in a
                                            timely manner. EMINENT is great!</blockquote>
                                        <div className="testimonial-bottom">
                                            <div className="star-ratings">
                                                <ul className="rating">
                                                    <li className="active"><i className="fa fa-star" /></li>
                                                    <li className="active"><i className="fa fa-star" /></li>
                                                    <li className="active"><i className="fa fa-star" /></li>
                                                    <li className="active"><i className="fa fa-star" /></li>
                                                    <li className="active"><i className="fa fa-star" /></li>
                                                </ul>
                                            </div>
                                            <div className="testimonial-caption">
                                                <h5>Alex Albert Belle</h5>
                                                <label>CEO-Textile</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>{/* testimonials end */}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HomeTestimonialSection;