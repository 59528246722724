import React from 'react';
import { NavLink } from 'react-router-dom';


const Navbar = () => {


    const navLinkStyles = ({ isActive }) => {
        return {
            fontWeight: isActive ? "bold" : "500",

            color: isActive ? "brown" : "black"

        }

    }

    return (
        <div>
            {/*header start*/}
            <header id="masthead" className="header ttm-header-style-03">
                {/* topbar */}
                <div className="top_bar ttm-bgcolor-darkgrey clearfix">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col-xl-12 d-flex flex-row align-items-center">
                                <div className="top_bar_contact_item">
                                    <div className="top_bar_icon"><i className="ti ti-email" /></div><a href="mailto:shakil@eminenttextiles.com">shakil@eminenttextiles.com</a>
                                </div>
                                <div className="top_bar_contact_item">
                                    <div className="top_bar_icon"><i className="ti ti-location-pin" /></div>Flat 2/A,House 04,Road
                                    13, Nikunja 2,Dhaka -1229, Bangladesh
                                </div>
                                <div className="top_bar_contact_item top_bar_social ml-auto p-0">
                                    <ul className="social-icons list-inline">
                                        <li><a className="tooltip-top" href="#" rel="noopener" aria-label="facebook" data-tooltip="Facebook"><i className="fa fa-facebook" /></a></li>
                                        <li><a className="tooltip-top" href="#" rel="noopener" aria-label="twitter" data-tooltip="Twitter"><i className="fa fa-twitter" /></a></li>

                                        <li><a className="tooltip-top" href="#" rel="noopener" aria-label="Whatsapp" data-tooltip="Whatsapp"><i className="fa fa-whatsapp" /></a></li>
                                        <li><a className="tooltip-top" href="#" rel="noopener" aria-label="linkedin" data-tooltip="Linkedin"><i className="fa fa-linkedin" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* topbar end */}
                {/* site-header-menu */}
                <div id="site-header-menu" className="site-header-menu ttm-bgcolor-white">
                    <div className="site-header-menu-inner ttm-stickable-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/*site-navigation */}
                                    <div className="site-navigation d-flex flex-row align-items-center justify-content-between">
                                        {/* site-branding */}
                                        <div className="site-branding ">
                                            <a className="home-link" href="index.html" title="Eminent Textiles" rel="home">
                                                <img id="logo-img" height={50} width={160} className="img-fluid auto_size" src="images/logo1.png" alt="logo-img" />
                                            </a>
                                        </div>{/* site-branding end */}
                                        <div className="d-flex flex-row m-auto">
                                            <div className="btn-show-menu-mobile menubar menubar--squeeze">
                                                <span className="menubar-box">
                                                    <span className="menubar-inner" />
                                                </span>
                                            </div>
                                            {/* menu */}
                                            <nav className="main-menu menu-mobile" id="menu">
                                                <div>
                                                    <ul className="menu">
                                                        <li className="mega-menu-item">
                                                            <NavLink to="/" style={navLinkStyles}>Home</NavLink>
                                                        </li>
                                                        <li className="mega-menu-item">
                                                            <NavLink to="/aboutus" style={navLinkStyles}>About Us</NavLink>
                                                        </li>
                                                        <li className="mega-menu-item">
                                                            <NavLink to="/"
                                                                className="mega-menu-link"

                                                            >Products</NavLink>
                                                            <ul className="mega-submenu">
                                                                <li><NavLink to="/mensjeans" >Men's Apparels</NavLink></li>
                                                                <li><NavLink to="/womensjeans" >Women's Apparels</NavLink></li>
                                                                <li><NavLink to="/boysjeans" >Boy's Apparels</NavLink></li>
                                                                <li><NavLink to="/girlsjeans" >Girl's Apparels</NavLink></li>
                                                                <li><NavLink to="/babysapparels" >Baby's Apparels</NavLink></li>
                                                                <li><NavLink to="/accessories" >Accessories</NavLink></li>
                                                            </ul>
                                                        </li>

                                                        <li className="mega-menu-item">
                                                            <NavLink to="/lifewithus" style={navLinkStyles} >Life With Us</NavLink>
                                                        </li>
                                                        <li className="mega-menu-item">
                                                            <NavLink to="/ourpartners" style={navLinkStyles} >Our Partners</NavLink>
                                                        </li>
                                                        <li className="mega-menu-item">
                                                            <NavLink to="/sustainableapparels" style={navLinkStyles} >Sustainable Apparels</NavLink>
                                                        </li>
                                                        <li className="mega-menu-item">
                                                            <NavLink to="/blog" style={navLinkStyles}>Blog</NavLink>
                                                        </li>
                                                        <li className="mega-menu-item ">
                                                            <NavLink to="/contact" style={navLinkStyles} >Contact us</NavLink>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </nav>{/* menu end */}
                                        </div>
                                        <div className="widget_info d-flex flex-row align-items-center">
                                            <div className="widget_icon ttm-textcolor-skincolor"><i className="flaticon-chat" />
                                            </div>
                                            <div className="widget_content">
                                                <h3 className="widget_title">
                                                    <a href="tel:+8801785-678228">+8801785-678228 </a><br />
                                                    <a href="tel:+8801766-555756">+8801766-555756</a>
                                                </h3>
                                                <p className="widget_desc">Have any Questions?</p>
                                            </div>
                                        </div>
                                    </div>{/* site-navigation end*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* site-header-menu end*/}
            </header>
            {/*header end*/}


        </div>
    );
};

export default Navbar;