import React from 'react';
import GirlsApparelBanner from '../GirlsApparelBanner';
import GirlsAside from '../GirlsAside';
import GirlsJeansItems from './GirlsJeansItems';

const GirlsJeans = () => {
    return (
        <div>
            <GirlsApparelBanner />
            <div className="title-header section-title title-style-center_text">
                <h2 className="title font">Girl's <b> Jeans </b></h2>
            </div>
            <div className="ttm-row sidebar ttm-sidebar-left clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-4 widget-area sidebar-left side-category">
                            <GirlsAside />

                        </div>
                        <div className="col-lg-8 content-area example">
                            <GirlsJeansItems />
                        </div>
                    </div>
                    {/* row end */}
                </div>
            </div>
        </div>
    );
};

export default GirlsJeans;