import React from 'react';

const StageOne = () => {
    return (
        <div>
            <section className="ttm-row about-section  clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="ttm_single_image-wrapper text-left">
                                <img style={{ width: 600, height: 300 }} className="img-fluid" src="images/slides/depositphotos_98647626-stock-photo-factory-detail-industry.jpg" alt="single-09" />
                            </div>
                        </div>
                        <div style={{ marginTop: 50 }} className="col-lg-6 col-md-12 col-xs-12 ">
                            <div className="padding_top20 res-991-padding_top40">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3 style={{ fontSize: 30 }}>Stage 1</h3>
                                    </div>
                                    <div className="title-desc">
                                        <p><b>To keep away from</b><br />
                                            Products without any Environmental aspects such as:
                                            <br />
                                        </p><li> Non-reusable or non-recyclable</li>
                                        <li>No organic content or no recycled content</li>
                                        <li>Questionable durability</li>
                                        <li>Requiring high temperature washing, polluting air and consume more water
                                        </li>
                                        <li>Hazard chemicals and process</li>
                                        <p />
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>

        </div>
    );
};

export default StageOne;