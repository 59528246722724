import React from 'react';

const OurGuideline = () => {
    return (
        <div>
            <section className="ttm-row about-section ttm-bgcolor-grey clearfix">
                <div className="container">
                    <div className="padding_top10 res-991-padding_top20">
                        {/* section title */}
                        <div style={{ textAlign: 'center' }} className="section-title">
                            <div className="section-title">
                                <div className="title-header">
                                    <h3 style={{ fontSize: 30 }}>Our Guideline to Product Conversion</h3>
                                </div>
                                <div className="title-desc">
                                    <h5>Our development guideline is to identify current customer materials and
                                        processes, then set a precise path mapping out journey towards preferred
                                        materials/products, and better working practices.
                                    </h5>
                                </div>
                            </div>{/* section title end */}
                        </div>
                    </div>
                </div></section>

        </div>
    );
};

export default OurGuideline;