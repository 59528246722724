import React from 'react';

const StageTwo = () => {
    return (
        <div>
            <section className="ttm-row about-section ttm-bgcolor-grey clearfix">
                <div className="container">
                    <div className="row">
                        <div style={{ marginTop: 50, textAlign: 'left' }} className="col-lg-6 col-md-12">
                            <div className="padding_top20 res-991-padding_top40">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3 style={{ fontSize: 30 }}>Stage 2</h3>
                                    </div>
                                    <div className="title-desc">
                                        <p><b>To the way of transition</b><br />
                                            Conventional materials or processes with some environmental or social
                                            attributes.
                                        </p><li>Preferred materials content with supporting certification and certifying
                                            the relevant content,selection of dyes and chemicals</li>
                                        <li>Recyclable or Reusable</li>
                                        <li>Durable design</li>
                                        <li>Less washing, dry cleaning &amp; ironing</li>
                                        <li>Reduce packaging</li>
                                        <p />
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xs-12">
                            <div className="ttm_single_image-wrapper text-left">
                                <img style={{ width: 600, height: 400 }} className="img-fluid" src="images/slides/1.webp" alt="single-09" />
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>

        </div>
    );
};

export default StageTwo;