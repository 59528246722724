import React from 'react';

const BackToTop = () => {
    return (
        <div>
            <a id="totop" href="#top">
                <i className="fa fa-angle-up"></i>
            </a>
        </div>
    );
};

export default BackToTop;