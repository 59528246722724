import React from 'react';

const Banner = () => {
    return (
        <div>
            <div className="banner_slider">
                <div className="slide " style={{ height: 400 }}>
                    <div className="slide_img " style={{ backgroundImage: 'url(images/slides/rsz_partners.jpg)' }}>
                    </div>
                    <div class="slide__content">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-sm-6 col-md-6 col-lg-3">
                                    <div class="slide__content--headings ttm-textcolor-white text-center banner-h2">
                                        <h1 data-animation="fadeInDown"><strong>"Our Partners"</strong></h1>
                                        {/* <h6 class="breadcrumb-wrapper">
                                            <span>
                                                <a title="Homepage" href="index.html">Home</a>
                                            </span>
                                            <span>Our Partners</span>
                                        </h6> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="slide__content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-sm-6 col-md-6 col-lg-3">
                                    <div className="slide__content--headings ttm-textcolor-white text-center banner-h2">
                                        <h2 data-animation="fadeInDown" > <strong>"Our Partners"
                                        </strong>
                                        </h2>
                                        <div className="breadcrumb-wrapper">
                                            <span>
                                                <a title="Homepage" href="index.html">Home</a>
                                            </span>
                                            <span>Our Partners</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Banner;