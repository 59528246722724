import React from 'react';

const ContactBanner = () => {
    return (
        <div>
            <div className="banner_slider">
                <div className="slide " style={{ height: 400 }}>
                    <div className="slide_img " style={{ backgroundImage: 'url(images/slides/CONTACT-US-LOGO.jpg)' }}>
                    </div>
                    <div className="slide__content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slide__content--headings ttm-textcolor-white text-center">
                                        <h1 data-animation="fadeInDown"> <strong>"Contact Us"
                                        </strong>
                                        </h1>
                                        {/* <h6 className="breadcrumb-wrapper">
                                            <span>
                                                <a title="Homepage" href="index.html">Home</a>
                                            </span>
                                            <span>Contact Us</span>
                                        </h6> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactBanner;