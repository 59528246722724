import React from 'react';

const OurSolutionSection = () => {
    return (

        < section className="ttm-row service-section ttm-bgcolor-grey position-relative z-index-2 clearfix" >
            <div className="container">
                {/* row */}
                <div className="row">
                    <div className="col-lg-12">
                        {/* section-title */}
                        <div className="section-title title-style-center_text">
                            <div className="title-header">
                                {/* <h3>INDUSTRIES &amp; PRODUCTION</h3> */}
                                <h2 className="title">Our <b>Solutions</b></h2>
                            </div>
                        </div>
                        {/* section-title end */}
                    </div>
                </div>
                {/* row end */}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="featuredbox-number">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style5">
                                        <i className="ttm-num ti-info" />
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="flaticon flaticon-coat" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Sampling, we keep it simple
                                                </h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>It is free of charge and we are happy to do sampling for you.
                                                    i.e Marketing, collection, SMS (condition applicable), Photo ,
                                                    Proto, Fit etc.
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style5">
                                        <i className="ttm-num ti-info" />
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="fa fa-cogs" />
                                                {/* <i class="fa fa-hand-grab-o"></i> */}
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Supply Chain, one of our strengths
                                                </h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>A quick response to ensure the required
                                                    product/trims/overseas
                                                    fabrics &amp; accessories is an attribute of our team.
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style5">
                                        <i className="ttm-num ti-info" />
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="flaticon flaticon-tshirt-1" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Design Consultation Support
                                                </h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>We take pride in our in-house design team, always prepared to
                                                    offer expert support for your technical files, artwork, and
                                                    styling designs whenever you need their assistance.
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style5">
                                        <i className="ttm-num ti-info" />
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="flaticon flaticon-jeans" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Fast-Track Sample And Production Services
                                                </h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p> We pride ourselves on delivering the swiftest sample support
                                                    and
                                                    as a single point of contact we cover the complete
                                                    production
                                                    chain to ensure impeccable quality and a smooth and pleasant
                                                    production experience.
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style5">
                                        <i className="ttm-num ti-info" />
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="flaticon flaticon-suit" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Merchandising </h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Based on your requirement we simply do the scanning,
                                                    negotiation
                                                    and high level segmentation for you, advising you to make
                                                    the
                                                    best decision. We stay up to date in real time, providing
                                                    status, ensuring a smooth execution and timely delivery,
                                                    minimising the risk for costly miscalculations.
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style5">
                                        <i className="ttm-num ti-info" />
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="fa fa-check-circle" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Quality Checks
                                                </h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Our quality control team carry out several quality checks
                                                    throughout the process and work with proven processes to
                                                    ensure
                                                    a quality as expected. we of course offer as many samples as
                                                    needed, both prior and under the production order process.
                                                    Review, Revise and get it Right.
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style5">
                                        <i className="ttm-num ti-info" />
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="flaticon flaticon-t-shirt" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Products
                                                </h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>We perform both on a larger scale as well as specific
                                                    projects.
                                                    Discover our wide range of products and don’t hesitate to
                                                    get in
                                                    touch on any ideas you might have, we love a good chat, cost
                                                    effective solutions and creative challenges.
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    {/*featured-icon-box*/}
                                    <div className="featured-icon-box icon-align-top-content style5">
                                        <i className="ttm-num ti-info" />
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="flaticon flaticon-delivery-truck" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Logistics
                                                </h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>As your partner we can take care of administration, delivery
                                                    condition agreements and custom clearance and we have vast
                                                    experience from various order volumes, country specific
                                                    terms
                                                    and other aspects affecting cost and efficiency.
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default OurSolutionSection;


